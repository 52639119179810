import { FC } from 'react';

import { useLinkComponent } from '@link';
import { mapImage } from '@sitecore/common';
import { ArticleCardRendering } from '@sitecore/types/manual/ArticleCard';
import { Box, Card, Heading, Image, Stack, TextLink } from '@sparky';

/** @deprecated Use `ContentCard` instead */
const ArticleCard: FC<ArticleCardRendering> = ({ fields, params }) => {
  const { title, link, image } = fields;
  const Link = useLinkComponent();
  const imageProps = mapImage(image);

  const headingLevel = params?.headingLevel ?? 'h3';

  return (
    <Card>
      {imageProps.src && (
        <Box overflow="hidden" borderRadius="m">
          <Image
            alt={imageProps.alt}
            width="100%"
            height="auto"
            src={imageProps.src}
            sources={imageProps.sources}
            objectFit="cover"
            aspectRatio="16/9"
          />
        </Box>
      )}
      <Box padding="6">
        <Stack gap="6">
          {title?.value && (
            <Heading size="S" as={headingLevel}>
              {title.value}
            </Heading>
          )}
          {link?.value.href && link?.value.text && (
            <Link linkType={link.value.linktype} linkValue={link.value}>
              <TextLink emphasis="high">{link.value.text}</TextLink>
            </Link>
          )}
        </Stack>
      </Box>
    </Card>
  );
};

export default ArticleCard;
