import RichText from '@components/RichText/RichText';
import { useLinkComponent } from '@link';
import { mapImage } from '@sitecore/common';
import { ProductCardRendering } from '@sitecore/types/manual/ProductCard';
import { Box, ButtonLink, Heading, Image, Stack, Stretch, Text, TextLink } from '@sparky';

/** @deprecated Use `ContentCard` instead */
const ProductCard: React.FC<ProductCardRendering> = ({ fields, params }) => {
  const Link = useLinkComponent();

  const headingLevel = params?.headingLevel ?? 'h3';
  const imageProps = mapImage(fields.image);

  return (
    <Stretch height>
      <Stack>
        {imageProps.src ? (
          <Stack.Item>
            <Box paddingBottom={{ initial: '6' }}>
              <Image
                src={imageProps.src}
                alt={fields?.image?.value?.alt ?? ''}
                width="100%"
                height="100%"
                objectFit="cover"
                sources={imageProps.sources}
              />
            </Box>
          </Stack.Item>
        ) : null}
        <Stack.Item>
          <Heading as={headingLevel} size={{ initial: 'XS', md: 'S' }}>
            {fields.title.value}
          </Heading>
        </Stack.Item>
        <Stack.Item grow>
          <Box paddingTop={{ initial: '6' }}>
            <Text size={{ initial: 'BodyM', md: 'BodyL' }}>
              <RichText html={fields.content.value} />
            </Text>
          </Box>
        </Stack.Item>

        <Box paddingTop={{ initial: '6' }}>
          <Stretch width={{ md: false, sm: true }}>
            {fields.buttonLink?.value.href && fields.buttonLink.value.text ? (
              <Stretch width={{ initial: true, md: false }}>
                <Link linkType={fields.buttonLink.value.linktype} linkValue={fields.buttonLink.value}>
                  <ButtonLink>{fields.buttonLink.value.text}</ButtonLink>
                </Link>
              </Stretch>
            ) : null}

            {fields.link?.value.href && fields.link?.value.text ? (
              <Stack.Item>
                <Link linkType={fields.link.value.linktype} linkValue={fields.link.value}>
                  <TextLink emphasis="high">{fields.link.value.text}</TextLink>
                </Link>
              </Stack.Item>
            ) : null}
          </Stretch>
        </Box>
      </Stack>
    </Stretch>
  );
};

export default ProductCard;
